import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth/auth.slice';
import sitesReducer from './sites/sites.slice';
import pageReducer from './page/page.slice';

const store = configureStore({
	
	reducer: {
		auth: authReducer,
		sites: sitesReducer,
		page: pageReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;