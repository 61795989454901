import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { apiRequest } from '../../lib/request';

import SitesList from './components/SitesList';

import { RootState } from '../../store/store';
import { setUserSites } from '../../store/sites/sites.slice';
import { setPageTitle } from '../../store/page/page.slice';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import SkeletonMui from '@mui/material/Skeleton';

import AddIcon from '@mui/icons-material/Add';

import { siteInterface } from '../../interfaces/site.interface';

import SitesListItem from './components/SitesListItem';

import { motion } from "framer-motion";

function PageSitesList() {

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [arSites, setArSites] = useState<siteInterface[] | undefined>(undefined);
	
	const dispatch = useDispatch();

	useEffect(() => {
		
		dispatch(setPageTitle('Мои сайты'));

		getSitest();

	}, []);

	return (
		<>
			<div className="sites__header">
				<Link to={'/sites/add/'}>
				<AddIcon/> Добавить сайт
				</Link>
			</div>
			{ isLoading 
				?
					<div className="site-list">
						{Array.from(Array(6).keys()).map((i) =>
							<div className="site__item is__skeleton" key={ i }>
								<div className="site__item-name">
									<Skeleton width={ 250 } height={ 33 }/>
									<Skeleton width={ 20 } height={ 20 }/>
								</div>
								<div className="site__item-dates">
									<div className="site__item-domain">
										<Skeleton width={ 15 } height={ 15 }/>
										<div className="site__item-domain-date">
											<Skeleton width={ 150 } height={ 15 }/>
										</div>
									</div>
									<div className="site__item-ssl">
										<Skeleton width={ 15 } height={ 15 }/>
										<div className="site__item-ssl-date">
											<Skeleton width={ 150 } height={ 15 }/>
										</div>
										
									</div>
								</div>
							</div>
						)}
					</div>
					
				:
					<motion.div initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.2 }}>
						<div className="site-list">
							{ arSites
								?
								arSites.map(
									(sitesListItemData: siteInterface) => <SitesListItem key={ sitesListItemData.ID } data={ sitesListItemData } />
								)
								:
								<></>
							}
						</div>
					</motion.div>
				}
		</>
	);


	async function getSitest() {

		try {

			const response = await apiRequest('/site/get-user-site-list/');
			
			setArSites(response.data.result);

		} catch(error) {

			console.error(error);

		} finally {

			

			setTimeout(() => {

				setIsLoading(false);

			}, 100);
		}
	}
}

export default PageSitesList;
