import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Navigate, Link, useLocation } from 'react-router-dom';
import { RootState } from '../../store/store';
import { isEmptyObject } from '../../lib/validate';

import { apiRequest } from '../../lib/request';


interface changePassErrorsInterface {
	password: undefined | string,
	confirm_password: undefined | string
};

function ChangePassword() {

	const location = useLocation();

	const [isChangeSuccess, setIsChangeSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [linkError, setLinkError] = useState<undefined|Array<string>>(undefined);
	
	const [resErrors, setResErrors] = useState<undefined | Array<string>>(undefined);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

	const isAuthorized = useSelector((state: RootState) => state.auth.isAuthorized);


	const [formValues, setFormValues] = useState({
		password: '',
		confirm_password: '',
	});

	const [formErrors, setFormErrors] = useState<changePassErrorsInterface>({
		password: undefined,
		confirm_password: undefined,
	});

	const urlParams = new URLSearchParams(location.search);

	const user = urlParams.get('user');
	const code = urlParams.get('code');

	const validateForm = () => {

		let errors = {password: '', confirm_password: ''};

		if(!formValues.password) {

			errors.password = 'Обязательное поле';

		} else if(formValues.password.length < 6) {

			errors.password = 'Минимум 6 символов';
		}


		if(!formValues.confirm_password) {

			errors.confirm_password = 'Обязательное поле';

		} else if(formValues.confirm_password !== formValues.password) {

			errors.confirm_password = 'Пароли не совпадают';
		}

		setFormErrors(errors);

		return isEmptyObject(errors);
	};


	useEffect(() => {

		if(code && user) {

			const chechRestoreLink = async () => {

				try {
					
					const response = await apiRequest('/user/chech-restore-link/', {
						user: user,
						code: code,
					}, true);

					const result = response?.data?.result;

					if(result?.error) {

						setLinkError(result.error);

					} else if(!result?.success) {

						setLinkError(['Ссылка недействительна или устарела']);
					}


				} catch(error) {
					

				} finally {

					setIsLoading(false);
				}
			}

			chechRestoreLink();

		} else {

			setLinkError(['Ссылка недействительна или устарела']);
			setIsLoading(false);
		}

	}, [code, user]);


	if(isLoading) {

		return <div className="loader"></div>;
	}

	if(linkError) {

		const outErrors = linkError.map(
			(error: string) => <div>{ error }</div>
		);

		return (
			<div className="auth__page">
				<div className="auth__form auth__form-restore-pass">
					<div className="auth__form-logo">
						<img src={require('../../../assets/img/logo.png')} alt="Logo" />
					</div>
					<div className="form__item">
						{ outErrors }
					</div>
					<div className="form__item form__item-btns">
						<Link className="btn btn__blue" to="/restore/">Запросить новую ссылку</Link>
					</div>
				</div>
			</div>
		);
	}

	if(isChangeSuccess) {

		return (
			<div className="auth__page">
				<div className="auth__form auth__form-restore-pass">
					<div className="auth__form-logo">
						<img src={require('../../../assets/img/logo.png')} alt="Logo" />
					</div>
					<div className="form__item">
						Пароль успешно изменен
					</div>
					<div className="form__item form__item-btns">
						<Link className="btn btn__blue" to="/login/">Продолжить</Link>
					</div>
				</div>
			</div>
		);
	}


	let outResErrors: React.JSX.Element[] | undefined = undefined;

	if(resErrors) {

		outResErrors = resErrors.map(
			(error: string, index: number) => <li key={ index }>{ error }</li>
		);
	}


	return (isAuthorized 
		? <Navigate to="/" state={{ from: location }} replace />
		: <div className="auth__page">
			<div className="auth__form auth__form-change-password">
				<div className="auth__form-logo">
					<img src={require('../../../assets/img/logo.png')} alt="Logo" />
				</div>
				<div className="form__title">
					Изменить пароль
				</div>
				<div className="form__item">
					<div className="form__item-label">
						Новый пароль
						{ formErrors.password &&  
							<div className="form__item-label-error">
								{ formErrors.password }
							</div>
						}
					</div>
					<input
						type="password"
						name="password"
						className={`form__item-input${formErrors.password ? ' form__item-input-error' : ''}`} 
						value={ formValues.password }
						onChange={ handleChange }
						onKeyUp={ handleKeyUp }
						/>
				</div>
				<div className="form__item">
					<div className="form__item-label">
						Подтверждение пароля
						{ formErrors.confirm_password &&  
							<div className="form__item-label-error">
								{ formErrors.confirm_password }
							</div>
						}
					</div>
					<input
						type="password"
						name="confirm_password"
						className={`form__item-input${formErrors.confirm_password ? ' form__item-input-error' : ''}`} 
						value={ formValues.confirm_password }
						onChange={ handleChange }
						onKeyUp={ handleKeyUp }
						/>
				</div>
				{ outResErrors && 
					<div className="form__error">
						<ul>
							{ outResErrors }
						</ul>
					</div>
				}
				<div className="form__item form__item-btns">
					<div className="form__item-btn">
						<button className={`btn btn__blue${isFormLoading ? ' is__loading' : ''}`} onClick={ handleChangePassword }>Изменить пароль</button>
					</div>
				</div>
			</div>
		</div>
	);
	

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {

		const { name, value } = event.target;

		setFormValues({
			...formValues,
			[name]: value
		});

		if((name === 'password' || name === 'confirm_password') && formErrors[name]) {

			setFormErrors({
				...formErrors,
				[name]: undefined
			});
		}
	};


	function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {

		if(event?.key && event.key == 'Enter') {

			handleChangePassword();
		}
	}


	function handleChangePassword() {

		setResErrors(undefined);

		if(validateForm()) {

			submitChangePassword(formValues.password, formValues.confirm_password);
		}
	}


	async function submitChangePassword(password: string, confirm_password: string) {

		if(isFormLoading) {

			return;
		};
	
		setIsFormLoading(true);

		try {

			const response = await apiRequest('/user/change-password/', {
				user: user,
				code: code,
				password: password,
				confirm_password: confirm_password,
			});

			const result = response?.data?.result;
	
			if(result?.success) {

				setIsChangeSuccess(true);

			} else if(result?.error) {

				setResErrors(result.error);
			}

		} catch(error) {

			setResErrors(['Error!']);
			console.error(error);

		} finally {

			setIsFormLoading(false);
		}
	}
	
}


export default ChangePassword;
