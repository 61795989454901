import React from 'react';
import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Link, useLocation } from 'react-router-dom';
import { RootState } from '../../store/store';
import { loginSuccess } from '../../store/auth/auth.slice';

import { apiRequest } from '../../lib/request';
import { validateEmail, isEmptyObject } from '../../lib/validate';


interface registrationErrorsInterface {
	name: undefined | string,
	email: undefined | string,
	password: undefined | string,
	confirm_password: undefined | string
};

function Registration() {

	const dispatch = useDispatch();
	const location = useLocation();

	const isAuthorized = useSelector((state: RootState) => state.auth.isAuthorized);

	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
	const [resErrors, setResErrors] = useState<undefined | Array<string>>(undefined);

	const [formValues, setFormValues] = useState({
		name: '',
		email: '',
		password: '',
		confirm_password: '',
	});

	const [formErrors, setFormErrors] = useState<registrationErrorsInterface>({
		name: undefined,
		email: undefined,
		password: undefined,
		confirm_password: undefined,
	});


	const validateForm = () => {

		let errors = {name: '', email: '', password: '', confirm_password: ''};

		if(!formValues.name) {
			
			errors.name = 'Обязательное поле';
		}
		

		if(!formValues.email) {

			errors.email = 'Обязательное поле';

		} else if(!validateEmail(formValues.email)) {

			errors.email = 'Некорректный E-mail';
		}


		if(!formValues.password) {

			errors.password = 'Обязательное поле';

		} else if(formValues.password.length < 6) {

			errors.password = 'Минимум 6 символов';
		}


		if(!formValues.confirm_password) {

			errors.confirm_password = 'Обязательное поле';

		} else if(formValues.confirm_password !== formValues.password) {

			errors.confirm_password = 'Пароли не совпадают';
		}

		setFormErrors(errors);

		return isEmptyObject(errors);
	};


	let outResErrors: React.JSX.Element[] | undefined = resErrors?.map(
		(error: string, index: number) => <li key={ index }>{ error }</li>
	);


	return (isAuthorized 
		? <Navigate to="/" state={{ from: location }} replace />
		: <div className="auth__page">
			<div className="auth__form auth__form-registration">
				<div className="auth__form-logo">
					<img src={require('../../../assets/img/logo.png')} alt="Logo" />
				</div>
				<div className="form__title">
					Регистрация
				</div>
				<div className="form__item">
					<div className="form__item-label">
						Ваше имя
						{ formErrors.name &&  
							<div className="form__item-label-error">
								{ formErrors.name }
							</div> 
						}
					</div>
					<input
						type="text"
						name="name"
						className={`form__item-input${formErrors.name ? ' form__item-input-error' : ''}`}
						value={ formValues.name }
						onChange={ handleChange }
						onKeyUp={ handleKeyUp }
						/> 
				</div>
				<div className="form__item">
					<div className="form__item-label">
						E-mail
						{ formErrors.email &&  
							<div className="form__item-label-error">
								{ formErrors.email }
							</div> 
						}
					</div>
					<input
						type="text"
						name="email"
						className={`form__item-input${formErrors.email ? ' form__item-input-error' : ''}`}
						value={ formValues.email }
						onChange={ handleChange }
						onKeyUp={ handleKeyUp }
						/> 
				</div>
				<div className="form__item">
					<div className="form__item-label">
						Пароль
						{ formErrors.password &&  
							<div className="form__item-label-error">
								{ formErrors.password }
							</div>
						}
					</div>
					<input
						type="password"
						name="password"
						className={`form__item-input${formErrors.password ? ' form__item-input-error' : ''}`} 
						value={ formValues.password }
						onChange={ handleChange }
						onKeyUp={ handleKeyUp }
						/>
				</div>
				<div className="form__item">
					<div className="form__item-label">
						Подтверждение пароля
						{ formErrors.confirm_password &&  
							<div className="form__item-label-error">
								{ formErrors.confirm_password }
							</div>
						}
					</div>
					<input
						type="password"
						name="confirm_password"
						className={`form__item-input${formErrors.confirm_password ? ' form__item-input-error' : ''}`} 
						value={ formValues.confirm_password }
						onChange={ handleChange }
						onKeyUp={ handleKeyUp }
						/>
				</div>
				{ outResErrors && 
					<div className="form__error">
						<ul>
							{ outResErrors }
						</ul>
					</div>
				}
				<div className="form__item form__item-btns">
					<div className="form__item-btn">
						<button className={`btn btn__blue${isFormLoading ? ' is__loading' : ''}`} onClick={ handleRegistration }>Зарегистрироваться</button>
					</div>
					<div className="form__item-link">
						<Link to="/login/">Войти</Link>
					</div>
				</div>
				<div className="form__item form__item-policy">
					Регистрируясь на сайте вы соглашаетесь с <Link to="/policy/" target="_blank">Политикой обработки персональных данных</Link>
				</div>
			</div>
		</div>
	);


	function handleChange(e: { target: { name: string; value: string; }; }) {

		const { name, value } = e.target;

		setFormValues({
			...formValues,
			[name]: value
		});

		if((name === 'name' || name === 'email' || name === 'password' || name === 'confirm_password') && formErrors[name]) {

			setFormErrors({
				...formErrors,
				[name]: undefined
			});
		}
	};


	function handleKeyUp(event: { key: string; }) {

		if(event?.key && event.key == 'Enter') {

			handleRegistration();
		}
	}


	function handleRegistration() {

		setResErrors(undefined);

		if(validateForm()) {

			submitRegistration(formValues.name, formValues.email, formValues.password, formValues.confirm_password);
		}
	}


	async function submitRegistration(name: string, email: string, password: string, confirm_password: string) {

		if(isFormLoading) {

			return;
		};
	
		setIsFormLoading(true);


		try {

			const response = await apiRequest('/user/registration/', {
				name: name,
				email: email,
				password: password,
				confirm_password: confirm_password,
			});

			const result = response?.data?.result;
	
			if(result?.success) {

				dispatch(loginSuccess(result.user));

			} else if(result?.error) {

				setResErrors(result.error);
			}

		} catch(error) {

			setResErrors(['Error!']);
			console.error(error);

		} finally {

			setIsFormLoading(false);
		}
	}

}

export default Registration;

