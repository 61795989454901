import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { getCookie } from '../../lib/cookies';

import { useDispatch } from 'react-redux';

import { siteInterface } from '../../interfaces/site.interface';
import { setPageTitle } from '../../store/page/page.slice';

import { apiRequest } from '../../lib/request';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Switch, Button, Checkbox } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Camera, Activity, ShieldCheck, SquareActivity, Trash2 } from 'lucide-react';

import { motion } from 'framer-motion';

function PageSiteEdit() {

	const [redirectToList, setRedirectToList] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>(false);
	const [isChechAvailability, setIsChechAvailability] = useState<boolean>(false);
	const [isChechSsl, setIsChechSsl] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [arSite, setArSite] = useState<siteInterface | null>(null);

	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

	const { id } = useParams();

	const location = useLocation();
	const dispatch = useDispatch();

	const handleClickOpen = () => {
		setIsOpenDeleteModal(true);
	};

	const handleClose = () => {
		setIsOpenDeleteModal(false);
	};

	useEffect(() => {
		
		getSite(id);

	}, [id]);

	if(redirectToList) {

		return <Navigate to="/sites/" state={{ from: location }} replace />;
	}

	return (
		isLoading ? (
			<>
				<Skeleton count={ 10 }/>
			</>
		) : (
			<motion.div initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.2 }}>
				<div className="site-edit__form">
					<input type="hidden" name="id" value={ arSite?.ID } />
					<div className="site-edit__id">{arSite?.ID}</div>
					<div className="site-edit__head">
						<div className="site-edit__name">
							{ arSite?.NAME }
						</div>
						<div className="site-edit__active">
							<label>
								Активность
								<Switch checked={isActive ? true : false} onChange={ onChangeActiveHandler }  disabled={ isSaving }/>
							</label>
						</div>
					</div>
					<div className="site-edit__params">
						<div className="site-edit__param">
							<label className="site-edit__param-label">
								<div className="site-edit__param-ico">
									<SquareActivity />
								</div>
								Проверять доступность
								<div className="site-edit__param-checkbox">
									<Checkbox checked={isChechAvailability ? true : false} onChange={ onChangeChechAvailabilityHandler } disabled={ isSaving } size="small" />
								</div>
							</label>
						</div>
						<div className="site-edit__param">
							<label className="site-edit__param-label">
								<div className="site-edit__param-ico">
									<ShieldCheck />
								</div>
								Проверять SSL сертификат
								<div className="site-edit__param-checkbox">
									<Checkbox name="chech-ssl" checked={isChechSsl ? true : false} onChange={ onChangeChechSslHandler } disabled={ isSaving } size="small" />
								</div>
							</label>
						</div>
					</div>
					<div className="site-edit__btns">
						<div className="site-edit__save">
							<Button variant="contained" onClick={ onClickSaveHandler } disabled={ isSaving }>
								Закрыть
							</Button>
						</div>
						<div className="site-edit__delete">
							<Button variant="text" onClick={ handleClickOpen } disabled={ isSaving }>
								<Trash2 size={ 16 }/>
							</Button>
						</div>
					</div>
				</div>

				<Dialog
					open={ isOpenDeleteModal }
					onClose={ handleClose }
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Вы действительно хотите удалить сайт?
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Мы больше не будем проверять ваш сайт. Вся статистика проверок сайта будет удалена.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={ handleClose }>Отменить</Button>
						<Button onClick={ onClickDeleteHandler } autoFocus>
							Удалить
						</Button>
					</DialogActions>
				</Dialog>


			</motion.div>
		)
	);


	function onClickSaveHandler() {

		saveForm();
	}


	function saveForm(additionRequestData = {}, redirectToList: boolean = true) {

		if(isSaving) {

			return;
		}

		setIsSaving(true);

		let requestData = {
			id: id,
			active: isActive,
			check_availability: isChechAvailability,
			check_ssl: isChechSsl,
		}

		requestData = {...requestData, ...additionRequestData};

		const response = apiRequest('/site/save-user-site/', requestData);

		response.then(response => {

			if(response?.data?.success) {

				if(redirectToList) {

					setRedirectToList(true);
				}

			} else if(response?.data?.error) {

				console.error(response.data.error);
			}
			
		}).catch(error => {
	
			console.error(error);

		}).finally(() => {

			setIsSaving(false);
		});
	}


	function onClickDeleteHandler() {

		const response = apiRequest('/site/delete-user-site/', {
			id: id,
		});

		response.then(response => {

			if(response?.data?.success) {

				setRedirectToList(true);

			} else if(response?.data?.error) {

				console.error(response.data.error);
			}
			
		}).catch(error => {
	
			console.error(error);
		});
	}

	function onChangeActiveHandler(e: { target: {
		name: any; checked: boolean | ((prevState: boolean) => boolean); 
	}; }) {

		saveForm({active: e.target.checked}, false);
		setIsActive(e.target.checked);
	}

	function onChangeChechAvailabilityHandler(e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) {

		saveForm({check_availability: e.target.checked}, false);
		setIsChechAvailability(e.target.checked);
	}

	function onChangeChechSslHandler(e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) {

		saveForm({check_ssl: e.target.checked}, false);
		setIsChechSsl(e.target.checked);
	}

	function getSite(siteId:string | undefined) {

		if(siteId === undefined) {

			return;
		}

		const response = apiRequest('/site/get-user-site/' + siteId + '/');

		response.then(response => {

			//console.log(response.data.result);
			
			setArSite(response.data.result);
			fillSiteFields(response.data.result);
			
		}).catch(error => {
	
			console.error(error);

		}).finally(() => {

			window.setTimeout(() => {

				setIsLoading(false);

			}, 50);
		});
	}


	function fillSiteFields(objSite: siteInterface) {

		setIsActive(objSite.ACTIVE);
		setIsChechAvailability(objSite.CHECK_AVAILABILITY);
		setIsChechSsl(objSite.CHECK_SSL);

		dispatch(setPageTitle(objSite.NAME));
	}

}

export default PageSiteEdit;
