import React, { useEffect, useState } from 'react';
import { useLocation, Navigate, Outlet, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


import Breadcrumbs from '../components/Breadcrumbs';
import LogOutButton from '../components/LogOutButton';

import { RootState } from '../store/store';
import { loginSuccess, loginFailure, logout } from '../store/auth/auth.slice';

import { setPageTitle } from '../store/page/page.slice';

import axios from 'axios';
import { apiRequest } from '../lib/request';

import { getCookie, setCookie } from '../lib/cookies';

import { motion } from 'framer-motion';


function PrivateRoute() {

	const [loading, setLoading] = useState(true);
	const isAuthorized = useSelector((state: RootState) => state.auth.isAuthorized);
	const user = useSelector((state: RootState) => state.auth.user);
	const location = useLocation();
	const dispatch = useDispatch();

	const pageTitle = useSelector((state: RootState) => state.page.title);

	const path = location.pathname;

	useEffect(() => {

		console.log('PrivateRoute');

	}, [path]);

	useEffect(() => {

		const token = getCookie('access_token');

		if(!token) {
			dispatch(logout());
			setLoading(false);
			return;
		}

		if(!isAuthorized) {

			const response = apiRequest('/user/check-auth/');

			response.finally(() => {

				setLoading(false);
			});

			//console.log(response);

			/*
			checkAuth(token)
				.then((response: any) => {
					if(response.data.result.success) {
						dispatch(loginSuccess(response.data.result.user));
					} else {
						dispatch(loginFailure(response.data.result.error));
					}
				})
				.catch((err: any) => {
					dispatch(loginFailure(err.message));
				})
				.finally(() => {
					setLoading(false);
				});
			*/

		} else {

			setLoading(false);
		}

		/*
		function checkAuth(token: string): any {
			return axios.get('https://lksrv.labsdev.ru/user/check-auth/', {
				headers: { token: token }
			});
		}
		*/

	}, [path, dispatch, isAuthorized]);


	useEffect(() => {

		//console.log('PrivateRoute');
		//console.log(getCookie('request'));

		if(getCookie('request') !== 'Ok') {

			apiRequest('/user/check-auth/');
		}

		//setCookie('request', 'No', 60000);

	}, [path]);




	if(loading) {
		return <div className="loader"></div>;
	}

	if(!isAuthorized) {
		return <Navigate to="/login/" state={{ from: location }} replace />;
	}

	/*
	if(user?.unconfirmed && path != '/confirm/') {
		console.log(path);
		return <Navigate to="/confirm/" state={{ from: location }} replace />;
	}
	/**/

	return user?.unconfirmed ? (
		<div className="page">
			<div className="container">
				Необходимо подтвердить аккаунт
			</div>
		</div>
	) : (

		<div className="dashboard">
			<div className="dashboard__left">
				<div className="left__menu">
					<div className="left__logo">
						<Link to="/" className="left__logo-link">
							<svg  xmlns="http://www.w3.org/2000/svg"  width="30"  height="30"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="1.5"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-wave-saw-tool"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12h5l4 8v-16l4 8h5" /></svg>
							Site<span>24</span>Up
						</Link>
					</div>
					<div className="left__menu-section">
						<Link to="/sites/" className="left__menu-section-title">
							<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-link"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 15l6 -6" /><path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464" /><path d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463" /></svg> Мои сайты
						</Link>
					</div>
					{/*
					<div className="left__menu-section">
						<Link to="/" className="left__menu-section-title">
							<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-bell-ringing"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /><path d="M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727" /><path d="M3 6.727a11.05 11.05 0 0 1 2.792 -3.727" /></svg> Настройки уведомлений
						</Link>
					</div>
					<div className="left__menu-section">
						<Link to="/" className="left__menu-section-title">
							<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-location-cog"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 18l-2 -4l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-3.14 8.697" /><path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M19.001 15.5v1.5" /><path d="M19.001 21v1.5" /><path d="M22.032 17.25l-1.299 .75" /><path d="M17.27 20l-1.3 .75" /><path d="M15.97 17.25l1.3 .75" /><path d="M20.733 20l1.3 .75" /></svg> Адреса уведомлений
						</Link>
					</div>
					<div className="left__menu-section">
						<Link to="/" className="left__menu-section-title">
							<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-id"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 4m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z" /><path d="M9 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M15 8l2 0" /><path d="M15 12l2 0" /><path d="M7 16l10 0" /></svg> Адреса
						</Link>
					</div>
					<div className="left__menu-section">
						<Link to="/" className="left__menu-section-title">
							<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-chart-dots"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 3v18h18" /><path d="M9 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M19 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M14 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M10.16 10.62l2.34 2.88" /><path d="M15.088 13.328l2.837 -4.586" /></svg> Статистика
						</Link>
					</div>
					*/}
				</div>
			</div>
			<div className="dashboard__right">
				<div className="dashboard__right-btn dashboard__right-btn-user"></div>
				<LogOutButton btnText="" btnClass="dashboard__right-btn dashboard__right-btn-logout"/>
			</div>
			<div className="dashboard__content">
				<div className="dashboard__page">
					<div className="page__head">
						{ pageTitle && <motion.h1 className="page__title" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.15 }}>{ pageTitle }</motion.h1> }
						<Breadcrumbs/>
					</div>
					<div className="page__block">
						<Outlet/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PrivateRoute;