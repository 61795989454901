import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { setPageTitle } from '../store/page/page.slice';

import { motion } from 'framer-motion';

function PageMain() {

	const dispatch = useDispatch();

	useEffect(() => {

		dispatch(setPageTitle('Дашборд'));
	});

	return (
		<motion.div className="main" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.15 }}>
			Is Main Page
		</motion.div>
	);
}

export default PageMain;
