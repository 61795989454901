import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { siteInterface } from '../../interfaces/site.interface';


interface SitesState {
	arSites: siteInterface[] | null;
}

const initialState: SitesState = {
	arSites: null,
};

const sitesSlice = createSlice({
	name: 'sites',
	initialState,
	reducers: {
		setUserSites(state, action: PayloadAction<siteInterface[]>) {
			state.arSites = action.payload;
		},
	},
});

export const { setUserSites } = sitesSlice.actions;

export default sitesSlice.reducer;