import React from 'react';
import { useDispatch } from 'react-redux';

import { logout } from '../store/auth/auth.slice';
import { setUserSites } from '../store/sites/sites.slice';


interface LogOutButtonProps {

	btnText?: string;
	btnClass?: string;
}


const LogOutButton = ({ btnText = 'Выйти', btnClass } : LogOutButtonProps) => {

	const dispatch = useDispatch();
  
	return (
		<div onClick={ handleLogout } className={ btnClass }>{ btnText }</div>
	);


	function handleLogout() {

		dispatch(logout());
		dispatch(setUserSites([]));
	};
};


export default LogOutButton;