import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { loginSuccess } from '../../store/auth/auth.slice';

import { apiRequest } from '../../lib/request';

import { isAxiosError } from 'axios';


function ConfirmUser() {

	const [loading, setLoading] = useState(true);
	const [confirmed, setConfirmed] = useState(false);
	const [confirmError, setConfirmError] = useState<Array<string>>([]);

	const dispatch = useDispatch();

	const location = useLocation();

	const urlParams = new URLSearchParams(location.search);
	const user = urlParams.get('user');
	const code = urlParams.get('code');


	useEffect(() => {

		if(code && user) {

			const confirmUser = async () => {

				try {

					const response = await apiRequest('/user/confirm/', {
						user: user,
						code: code,
					}, true);

					const result = response?.data?.result;

					if(result) {

						if(result.success) {

							setConfirmed(true);

							dispatch(loginSuccess(result.user));

						} else if(result.errors) {

							setConfirmError(result.errors);
						}

					} else {

						setConfirmError(['Ошибка подтверждения']);
					}
					
				} catch(error) {

					if(isAxiosError(error) && error?.message) {

						setConfirmError([error.message]);
					}

				} finally {

					setLoading(false);
				}
			}

			confirmUser();

		} else {

			setConfirmError(['Неверный код подтверждения']);
			setLoading(false);
		}

	}, [code, user]);
	

	if(loading) {

		return <div className="loader"></div>;
	}


	return (
		<div className="auth__page">
			<div className="auth__form auth__form-confirm">
				<div className="auth__form-logo">
					<img src={require('../../../assets/img/logo.png')} alt="Logo" />
				</div>
				<div className="auth__form-note">{confirmed ? 'Ваш аккаунт подтвержден' : confirmError.length > 0 ? confirmError.join(', ') : 'Ошибка подтверждения'}</div>
				<div className="auth__form-confirm-btns">
					<Link className="btn btn__red" to="/">Продолжить</Link>
				</div>
			</div>
		</div>
	);
}


export default ConfirmUser;
