import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { RootState } from '../store/store';

import { motion } from 'framer-motion';


const Breadcrumbs = () => {

	const pageTitle = useSelector((state: RootState) => state.page.title);

	const routes = [

		{ path: '/', breadcrumb: 'Главная' },

		{ path: '/sites/', breadcrumb: 'Мои сайты' },
		{ path: '/sites/add/', breadcrumb: 'Добавить сайт' },
		{ path: '/sites/:id/', breadcrumb: getSiteBreadcrumb }
	];

	const breadcrumbs = useBreadcrumbs(routes);
  
	return (
		<div className="page__breadcrumbs">
			<ul>
			{breadcrumbs.map(({ match, breadcrumb }, index) => (
				<motion.li key={ match.pathname } initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.15 }}>
					{ ((index + 1) === breadcrumbs.length) 
						? <>
							<span>{ breadcrumb }</span>
						</> 
						: <>
							<Link to={ match.pathname + (match.pathname === '/' ? '' : '/') }>
								{ breadcrumb }
							</Link>
						</>
					}
				</motion.li>
			))}
			</ul>
		</div>
	);


	function getSiteBreadcrumb(match:any) {
	
		return <>{ pageTitle }</>;
	};
};


export default Breadcrumbs;