import axios from 'axios';
import { AxiosResponse } from 'axios';
import { toast } from 'sonner';

import { getCookie, setCookie } from '../lib/cookies';

import store from '../store/store';
import { loginSuccess, logout } from '../store/auth/auth.slice';

const apiDomain = 'https://www.api.site24up.ru';

function apiRequest(path:string, postData:any = undefined, withoutAuth:boolean = false) {

	//console.log('apiRequest');
	setCookie('request', 'Ok', 60000);

	let response:undefined | Promise<AxiosResponse<any, any>>;

	let params = {};
	let token:undefined | string = undefined;

	if(!withoutAuth) {

		token = getCookie('access_token') ? getCookie('access_token') : '';
		const headers = {token: token};

		params = {headers: headers};
	}

	if(postData) {

		response = axios.post(apiDomain + path, postData, params);

	} else {

		response = axios.get(apiDomain + path, params);
	}

	if(!withoutAuth) {
		
		checkResponseAuth(response, token);
	}

	checkResponseError(response);

	return response;
}



function checkResponseAuth(response:undefined | Promise<AxiosResponse<any, any>>, token:undefined | string) {

	if(!response) {

		return;
	}

	response.then(response => {

		const responseData = response.data;

		if(!responseData.success) {

			return;
		}

		if(!responseData.user?.success) {

			//console.log('!!!!!!!!!!');
			//console.log(responseData);

			setCookie('access_token', '');
			
			store.dispatch(logout());

			//window.location.replace('/login/');

		} else {

			if(!responseData?.user?.token) {

				responseData.user.token = token;
			}

			store.dispatch(loginSuccess(responseData.user));
		}

		//console.log('In checkResponseAuth response:');
		//console.log(response);
		
	});
}


function checkResponseError(response:undefined | Promise<AxiosResponse<any, any>>) {

	if(!response) {

		return;
	}

	const promise = () => new Promise((resolve) => setTimeout(() => resolve({ name: 'Sonner' }), 2000));

	toast.promise(response, {
		loading: 'Loading...',
		success: (data) => {
			return `${data?.statusText} ${data?.config?.url}`;
		},
		error: 'Error',
	});

	

	response.then(response => {

		const responseData = response.data;

		if(!responseData.success) {

			//return;
		}

		if(!responseData.success?.auth?.success) {

			//return;
		}

		//console.log('In checkResponseAuth response:');
		//console.log(response);
		
	}).catch(error => {

		//console.log('In checkResponseAuth error:');
		//console.log(error);

	}).finally(() => {

		setCookie('request', 'No', 60000);
	});
}


export { apiRequest };