import React from 'react';
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { getCookie } from '../../lib/cookies';

import { siteInterface } from '../../interfaces/site.interface';

import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../store/page/page.slice';

import { motion } from "framer-motion";

import { toast } from 'sonner';

function PageSiteAdd() {

	const [redirectToList, setRedirectToList] = useState<boolean>(false);

	const [siteName, setSiteName] = useState<string>('');
	const [isActive, setIsActive] = useState<boolean>(false);
	const [isChechAvailability, setIsChechAvailability] = useState<boolean>(false);
	const [isChechSsl, setIsChechSsl] = useState<boolean>(false);

	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {

		dispatch(setPageTitle('Добавить сайт'));
	});

	if(redirectToList) {

		return <Navigate to="/sites/" state={{ from: location }} replace />;
	}

	return (
		<motion.div initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.2 }}>
			<div className="site-edit__form">
				<div className="site-edit__name">
					<input type="text" name="name" value={ siteName } onChange={ onChangeSiteNameHandler } />
				</div>
				<div className="site-edit__active">
					Активность
					<input type="checkbox" name="active" checked={isActive ? true : false} onChange={ onChangeActiveHandler }/>
				</div>
				<div className="site-edit__active">
					Проверять доступность
					<input type="checkbox" name="chech-availability" checked={isChechAvailability ? true : false} onChange={ onChangeChechAvailabilityHandler }/>
				</div>
				<div className="site-edit__active">
					Проверять SSL сертификат
					<input type="checkbox" name="chech-ssl" checked={isChechSsl ? true : false} onChange={ onChangeChechSslHandler }/>
				</div>
				<div className="site-edit__save">
					<button onClick={ onClickAddHandler }>Сохранить</button>
				</div>
			</div>
		</motion.div>
	);


	async function onClickAddHandler() {

		const token = getCookie('access_token') ? getCookie('access_token') : '';

		try {

			const response = await axios.post('https://lksrv.labsdev.ru/site/add-user-site/', {
					name: siteName,
					active: isActive,
					check_availability: isChechAvailability,
					check_ssl: isChechSsl,
				},
				{
					headers: {token: token}
				}
			);

			if(response.data.success) {

				

			} else if(response.data.error) {


			}

			setRedirectToList(true);
			console.log(response.data);

		} catch (error) {

			console.error(error);
		}
	}


	function onChangeSiteNameHandler(e: { target: { name: string; value: string; }; }) {

		setSiteName(e.target.value);
	}

	function onChangeActiveHandler(e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) {

		setIsActive(e.target.checked);
	}

	function onChangeChechAvailabilityHandler(e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) {

		setIsChechAvailability(e.target.checked);
	}

	function onChangeChechSslHandler(e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) {

		setIsChechSsl(e.target.checked);
	}

	

}

export default PageSiteAdd;
