import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Link, useLocation } from 'react-router-dom';
import { RootState } from '../../store/store';
import { loginSuccess } from '../../store/auth/auth.slice';

import { apiRequest } from '../../lib/request';

import { motion } from "framer-motion";

import { toast } from 'sonner';

function Login() {

	const dispatch = useDispatch();
	const location = useLocation();

	const isAuthorized = useSelector((state: RootState) => state.auth.isAuthorized);

	const [resErrors, setResErrors] = useState<undefined | Array<string>>(undefined);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

	const [formValues, setFormValues] = useState({
		email: '',
		password: '',
	});


	const [formErrors, setFormErrors] = useState({
		email: false,
		password: false,
	});


	let outResErrors: React.JSX.Element[] | undefined = undefined;

	if(resErrors) {

		outResErrors = resErrors.map(
			(error: string, index: number) => <li key={ index }>{ error }</li>
		);
	}

	return (isAuthorized 
		? <Navigate to="/" state={{ from: location }} replace />
		: <div className="auth__page">
			<motion.div className="auth__form auth__form-auth" initial={{ opacity: 0.1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
				<div className="auth__form-logo">
					<img src={require('../../../assets/img/logo.png')} alt="Logo" />
				</div>
				<div className="form__title">
					Вход на сайт
				</div>
				<div className="form__item">
					<div className="form__item-label">
						E-mail
						{ formErrors.email &&  
							<div className="form__item-label-error">
								Заполните поле
							</div> 
						}
					</div>
					<input
						type="text"
						name="email"
						className={`form__item-input${formErrors.email ? ' form__item-input-error' : ''}`}
						value={ formValues.email }
						onChange={ handleChange }
						onKeyUp={ handleKeyUp }
						/> 
				</div>
				<div className="form__item">
					<div className="form__item-label">
						Пароль
						{ formErrors.password &&  
							<div className="form__item-label-error">
								Заполните поле
							</div>
						}
					</div>
					<input
						type="password"
						name="password"
						className={`form__item-input${formErrors.password ? ' form__item-input-error' : ''}`} 
						value={ formValues.password }
						onChange={ handleChange }
						onKeyUp={ handleKeyUp }
						/>
				</div>
				{ outResErrors && 
					<div className="form__error">
						<ul>
							{ outResErrors }
						</ul>
					</div>
				}
				<div className="form__item form__item-btns">
					<div className="form__item-btn">
						<button className={`btn btn__blue${isFormLoading ? ' is__loading' : ''}`} onClick={ handleLogin }>Войти</button>
					</div>
				</div>
				<div className="form__item form__item-links">
					<Link to="/registration/">Зарегистрироваться</Link>
					<Link to="/restore/">Забыли пароль?</Link>
				</div>
			</motion.div>
		</div>
	);
	

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {

		setResErrors(undefined);

		const { name, value } = event.target;

		setFormValues({
			...formValues,
			[name]: value
		});

		setFormErrors({
			...formErrors,
			[name]: value === ''
		});
	};


	function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {

		if(event?.key && event.key === 'Enter') {

			handleLogin();
		}
	}


	function handleLogin() {

		setResErrors(undefined);

		const newErrors = {
            email: formValues.email === '',
            password: formValues.password === ''
        };

        setFormErrors(newErrors);

        if(!newErrors.email && !newErrors.password) {

			submitLogin(formValues.email, formValues.password);
        }
	}


	async function submitLogin(email: string, password: string) {

		if(isFormLoading) {

			return;
		};
	
		setIsFormLoading(true);
	
		try {

			const response = await apiRequest('/user/auth/', { email, password });

			const result = response?.data?.result;
	
			if(result?.success) {

				dispatch(loginSuccess(result.user));

				setFormValues({
					email: '',
					password: ''
				});

			} else if(result?.error) {

				//setResErrors(result.error);

				toast.error(result.error);
			}

		} catch(error) {

			setResErrors(['Error!']);
			console.error(error);

		} finally {

			setIsFormLoading(false);
		}
	}


}

export default Login;
