function validateEmail(email: string): boolean {
	
	const emailRegexp = new RegExp(/^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i);

	return emailRegexp.test(email);
}


function isEmptyObject(object: any): boolean {

    for(let key in object) {

        if(object.hasOwnProperty(key) && !!object[key]) {

            return false;
        }
    }

    return true;
}


export { validateEmail, isEmptyObject };