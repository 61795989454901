import React from 'react';
import { Link } from 'react-router-dom';


import { Switch } from '@mui/material';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import PublicIcon from '@mui/icons-material/Public';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { siteInterface } from '../../../interfaces/site.interface';

interface SitesListItemProps {
	data: siteInterface;
}

function SitesListItem(props: SitesListItemProps) {

	const siteId = props.data.ID;

	return (
		<div className={ props.data.ACTIVE ? 'site__item' : 'site__item disabled' }>
			<div className="site__item-name">
				{props.data.NAME}
				<DoneOutlinedIcon/>
				<Link to={'/sites/' + siteId + '/'}>
					<SettingsOutlinedIcon/>
				</Link>
			</div>
			<div className="site__item-dates">
				<div className={ props.data.DOMAIN_EXPIRATION ? 'site__item-domain' : 'site__item-domain disabled' }>
					<PublicIcon/>
					{ props.data.ACTIVE && props.data.DOMAIN_EXPIRATION && (
						<div className="site__item-domain-date">
							{props.data.DOMAIN_EXPIRATION}
						</div>
					) }
				</div>
				<div className={ props.data.CHECK_SSL ? 'site__item-ssl' : 'site__item-ssl disabled' }>
					<HttpsOutlinedIcon/>
					{ props.data.ACTIVE && props.data.CHECK_SSL && props.data.SSL_VALID_TO && (
						<div className="site__item-ssl-date">
							{props.data.SSL_VALID_TO}
						</div>
					) }
					
				</div>
			</div>
		</div>
	);
};

export default SitesListItem;