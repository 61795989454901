import React from 'react';

function Page404() {

	return (
		<div className="main">
			Is 404 Page
		</div>
	);
}

export default Page404;
