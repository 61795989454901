import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { Navigate, Link, useLocation } from 'react-router-dom';

import { RootState } from '../../store/store';

import { apiRequest } from '../../lib/request';

function RestorePassword() {

	const location = useLocation();

	const isAuthorized = useSelector((state: RootState) => state.auth.isAuthorized);

	const [resErrors, setResErrors] = useState<undefined | Array<string>>(undefined);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
	const [restoreSuccessMessage, setRestoreSuccessMessage] = useState<string>('');

	const [formValues, setFormValues] = useState({
		email: '',
	});

	const [formErrors, setFormErrors] = useState({
		email: false,
	});


	let outResErrors: React.JSX.Element[] | undefined = undefined;

	if(resErrors) {

		outResErrors = resErrors.map(
			(error: string, index: number) => <li key={ index }>{ error }</li>
		);
	}


	return (isAuthorized 
		? <Navigate to="/" state={{ from: location }} replace />
		: (restoreSuccessMessage
			? 
			<div className="auth__page">
				<div className="auth__form auth__form-restore-pass">
					<div className="auth__form-logo">
						<img src={require('../../../assets/img/logo.png')} alt="Logo" />
					</div>
					<div className="form__item">
						{ restoreSuccessMessage }
					</div>
					<div className="form__item form__item-btns">
						<Link className="btn btn__blue" to="/login/">Продолжить</Link>
					</div>
				</div>
			</div>
			: 
			<div className="auth__page">
				<div className="auth__form auth__form-restore-pass">
					<div className="auth__form-logo">
						<img src={require('../../../assets/img/logo.png')} alt="Logo" />
					</div>
					<div className="form__title">
						Восстановить пароль
					</div>
					<div className="form__item">
						<div className="form__item-label">
							E-mail
							{ formErrors.email &&  
								<div className="form__item-label-error">
									Заполните поле
								</div> 
							}
						</div>
						<input
							type="text"
							name="email"
							className={`form__item-input${formErrors.email ? ' form__item-input-error' : ''}`}
							value={ formValues.email }
							onChange={ handleChange }
							onKeyUp={ handleKeyUp }
							/> 
					</div>
					{ outResErrors && 
						<div className="form__error">
							<ul>
								{ outResErrors }
							</ul>
						</div>
					}
					<div className="form__item form__item-btns">
						<div className="form__item-btn">
							<button className={`btn btn__blue${isFormLoading ? ' is__loading' : ''}`} onClick={ handleRestore }>Восстановить</button>
						</div>
						<div className="form__item-link">
							<Link to="/login/">Войти</Link>
						</div>
					</div>
				</div>
			</div>
		)
	);


	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {

		const { name, value } = event.target;

		setFormValues({
			...formValues,
			[name]: value
		});

		setFormErrors({
			...formErrors,
			[name]: value === ''
		});
	};


	function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {

		if(event?.key && event.key == 'Enter') {

			handleRestore();
		}
	}


	function handleRestore() {

		setResErrors(undefined);

		const newErrors = {
            email: formValues.email === '',
        };

        setFormErrors(newErrors);

        if(!newErrors.email) {

			submitLogin(formValues.email);
        }
	}


	async function submitLogin(email: string) {

		if(isFormLoading) {

			return;
		};
	
		setIsFormLoading(true);

		try {

			const response = await apiRequest('/user/restore-password/', {
				email: email
			}, true);

			const result = response?.data?.result;
	
			if(result?.SUCCESS) {

				setRestoreSuccessMessage(result?.MESSAGE ? result?.MESSAGE : 'На ваш E-mail отправлена ссылка для восстановления пароля');

			} else if(result?.ERRORS) {

				setResErrors(result.ERRORS);
			}
			
		} catch(error) {

			setResErrors(['Error!']);
			console.error(error);

		} finally {

			setIsFormLoading(false);
		}
	}
}

export default RestorePassword;
