import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pagePropsInterface } from '../../interfaces/page.interface';


interface PagePropsState {
	title: string;
}

const initialState: PagePropsState = {
	title: '',
};

const pageSlice = createSlice({
	name: 'page',
	initialState,
	reducers: {
		setPageTitle(state, action: PayloadAction<string>) {
			console.log('SetTitle: ' + action.payload);
			state.title = action.payload;
		},
	},
});

export const { setPageTitle } = pageSlice.actions;

export default pageSlice.reducer;